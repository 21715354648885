<template>

  <div
    class="app-news-detail"
    role="content">

    <p
      class="preview-title">{{ title }}
    </p>

    <ul class="news-detail-info">

      <template
        v-for="(item, i) in list">

        <li
          class="news-detail-item"
          v-if="data[item.prop]"
          :key="i">

          <i :class="['iconfont', 'news-detail-icon', item.icon]"></i>

          <span
            class="datetime">{{item.label}}: {{ data[item.prop] }}
        </span>

        </li>

      </template>

    </ul>

    <!-- <p
      class="preview-info">

      <span
        class="datetime">time: {{ date }}
      </span>

      <span
        v-if="source"
        class="source">source: {{ source }}
      </span>

      <span class="click-num">clickNum: {{ clickNum }}
      </span>

    </p> -->

    <el-divider>
    </el-divider>

    <div
      v-html="content"
      class="rich-text news">
    </div>

  </div>

</template>

<script>
import * as api from '@/configs/api'
export default {
  name: 'app-news-detail',
  data () {
    return {
      author: 'Ocean big data center',
      browsing: 198,
      clickNum: 1000,
      content: '2222',
      data: {
        date: '',
        source: '',
        clickNum: ''
      },
      date: 'Jan 14, 2021',
      list: [
        {
          icon: 'icon-time',
          label: 'Time',
          prop: 'date'
        },
        {
          icon: 'icon-zhongxin',
          label: 'Source',
          prop: 'source'
        },
        {
          icon: 'icon-view',
          label: 'Visits',
          prop: 'clickNum'
        }
      ],
      id: '212312311123',
      source: '',
      title: 'Global 14 units and scholars jointly released "2020 annual report" on global ocean warming'
    }
  },
  watch: {
    $route: 'fetchData'
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const query = this.$route.query
      const url = api[query.type].detail
      const params = {
        Id: query.id
      }
      this.axios.get(url, {
        params
      }).then(response => {
        const res = response.data
        if (res.code === 200) {
          this.title = res.data.newsTitle
          this.source = res.data.newsSource
          this.data.date = res.data.publishTime.replace(/T/, ' ').replace(/\..*/, '')
          this.content = res.data.richTextContent
          this.data.clickNum = res.data.clickNum
        }
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-news-detail {
  background-color: #FFFFFF;
  box-sizing: border-box;
  margin: {
    bottom: 30px;
    top: 50px;
  };
  padding: {
    bottom: 20px;
    left: 20px;
    right: 20px;
    top: 20px;
  };
}

.preview-title {
  color: dodgerblue;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 25px;
  margin-top: 25px;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}

.news-detail-info {
  align-items: center;
  color: darkgray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 15px;
  margin-top: 15px;
}

.news-detail-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.news-detail-icon {
  color: dodgerblue;
  margin-right: 5px;
}

.preview-info {
  color: darkgray;
  font-size: 14px;
  margin-bottom: 15px;
  margin-left: 25px;
  margin-top: 15px;
  text-align: center;
}
.preview-info .datetime {
  margin-right: 25px;
}
.preview-info .source {
  margin-right: 25px;
}
.rich-text {
  padding-left: 29px;
  padding-right: 29px;
  text-align: left;
}
</style>
