const application = {
  detail: '/generalApplicationService/getDetailById',
  page: '/generalApplicationService/selectPage'
}

const basket = {
  add: '/dataBasket/add'
}

const dictionary = {
  type: '/dictData/getByType'
}

const metadata = {
  info: '/metadataInfo/detail',
  page: '/metadataInfo/page',
  size: 'specialProducts/getFtpFileSize'
}

const news = {
  detail: '/generalNews/getPicNewsDetailById',
  page: '/generalNews/selectPage'
}

const other = {
  visit: '/ipRecords/visit'
}

const result = {
  info: '/achievementInfo/detail', // 详情
  pageSys: '/achievementInfo/pageSys'
}

const subject = {
  download: '/specialProducts/download',
  downloadMore: '/specialProducts/downloadFiles',
  files: '/specialProducts/getFtpFileList'
}

export {
  application,
  basket,
  dictionary,
  metadata,
  news,
  other,
  result,
  subject
}
